import React from 'react';
import { Link } from 'gatsby';

import * as styles from '../css/pages/PrivacyPolicy.module.scss';

import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';

const PrivacyPolicy = () => (
    <>
        <SEO title="Privacy Policy" path="/privacy-policy/" />
        <Layout mainClass={styles.PrivacyPolicy}>
            <section className={styles.content}>
                <h1>Privacy policy</h1>
                <hr />
                <h2>Introduction</h2>
                <p>
                    Roundups Fundraising Services Ltd understands that your
                    information is important and in the wrong hands it poses a
                    risk to you rights and freedoms. In order to help you
                    protect your information this notice will explain what
                    information we collect, why we collect it, what we do with
                    it, how long we keep it for, who can access it and what your
                    rights are.
                </p>
                <h3>What we need</h3>
                <p>
                    We need to collect the following information from all site
                    visitors:
                </p>
                <ul>
                    <li>IP address</li>
                </ul>
                <p>
                    We need to collect the following information from Ripples
                    members:
                </p>
                <ul>
                    <li>Date member joined</li>
                    <li>First name</li>
                    <li>Surname</li>
                    <li>Email address</li>
                    <li>Gift aid declaration (if applicable)</li>
                    <li>UK address (if you opt in to Gift Aid)</li>
                    <li>Chosen good cause/s</li>
                    <li>Date chosen</li>
                    <li>
                        Round up figure (see <Link to="/faqs/">FAQs</Link> for
                        more information)
                    </li>
                    <li>Who you bank with</li>
                    <li>Amount donated since joining</li>
                </ul>
                <p>
                    We need to collect the following information from Good
                    Causes:
                </p>
                <ul>
                    <li>Organisation name</li>
                    <li>Organisation website</li>
                    <li>Organisation registration number</li>
                    <li>Lead contact person’s name</li>
                    <li>Lead contact person’s email address</li>
                    <li>Lead contact person’s phone number</li>
                    <li>Organisation logo</li>
                    <li>
                        Information and imagery relating to the organisation’s
                        work, mission and current projects
                    </li>
                    <li>Organisation bank account name</li>
                    <li>Organisation bank account number </li>
                    <li>Organisation bank sort code</li>
                </ul>
                <h3>Why we need it</h3>
                <p>We need to collect this information to:</p>
                <ul>
                    <li>Follow up with enquiries</li>
                </ul>
                <h3>What we do with it</h3>
                <p>
                    We receive the data listed above through the cookies on our
                    website OR through form submissions on our website/social
                    media OR through direct messages, emails or telephone calls.
                    The data is processed and stored in secure online databases
                    that are only accessible by Roundups Fundraising Services
                    Ltd.
                </p>
                <p>We will not transfer your data to another country.</p>
                <p>We do not use automated decision making.</p>
                <h3>How long we keep it</h3>
                <p>
                    We generally retain your information 12 months and 30 days
                    after your last engagement with our website or app.
                </p>
                <h3>How it will affect you</h3>
                <p>
                    The results of the process may have the following effects on
                    you:
                </p>
                <ul>
                    <li>
                        You may receive periodic emails from Roundups
                        Fundraising Services Ltd and your chosen charity
                    </li>
                    <li>
                        You may be subject to advertising from Roundups
                        Fundraising Services Ltd social media
                    </li>
                </ul>
                <h3>Who can see it</h3>
                <p>
                    For members of the Ripples platform, the parties that can
                    access this information are:
                </p>
                <ul>
                    <li>The good cause/s that you have chosen to support</li>
                    <li>
                        The individual that referred you to the Ripples platform
                        (if applicable)
                    </li>
                    <li>
                        We partner with the financial services company,{' '}
                        <a
                            target="_blank"
                            href="https://www.saltedge.com/"
                            rel="noreferrer">
                            Salt Edge Limited
                        </a>
                        , to access and retrieve your Account Information in a
                        secure way that adheres to PSD2 regulations. You can
                        read more about Salt Edge in our{' '}
                        <Link to="/faqs/">FAQs</Link>. By using Ripples, you
                        indicate that you have read and agree to the Salt Edge
                        <a
                            target="_blank"
                            href="https://www.saltedge.com/pages/end_user_license_terms"
                            rel="noreferrer">
                            Terms of Service
                        </a>
                        .
                    </li>
                </ul>
                <p>
                    For good causes who join the Ripples platform, the parties
                    that can access this information, with the exception of any
                    banking information, are:
                </p>
                <ul>
                    <li>Website visitors</li>
                    <li>App users</li>
                </ul>
                <h3>How we protect it</h3>
                <p>
                    We implement a variety of organisational and technical
                    controls including:
                </p>
                <ul>
                    <li>Data encryption;</li>
                    <li>Anonymisation through tokenisation; </li>
                    <li>Regular system reviews;</li>
                    <li>Password protection;</li>
                    <li>Having a nominated data protection champion;</li>
                </ul>
                <h3>What your rights are</h3>
                <p>You can:</p>
                <ul>
                    <li>
                        Request a copy of the data we hold on you. If you wish
                        to do this, please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        with your request or write to us at Roundups Fundraising
                        Services Ltd, 97 Charlotte Street, London, W1T 4QA.
                    </li>
                    <li>
                        Request that we correct or update the data that we hold
                        on you. If you wish to do this, please update the
                        settings inside your Ripples account in the first
                        instance. If this does not satisfy your requirements,
                        please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        with your request or write to us at Roundups Fundraising
                        Services Ltd, 97 Charlotte Street, London, W1T 4QA.
                    </li>
                    <li>
                        Request that we delete the information we hold on you.
                        If you wish to do this, please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        with your request or write to us at Roundups Fundraising
                        Services Ltd, 97 Charlotte Street, London, W1T 4QA.
                    </li>
                    <li>
                        Request that we send a copy of your data to another
                        organisation. If you wish to do this, please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        with your request or write to us at Roundups Fundraising
                        Services Ltd, 97 Charlotte Street, London, W1T 4QA.
                    </li>
                    <li>
                        Object to how we are using data. If you wish to do this,
                        please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        or write to us at Roundups Fundraising Services Ltd, 97
                        Charlotte Street, London, W1T 4QA.
                    </li>
                    <li>
                        Raise a compliant with the Information commissioner’s
                        office about us. If you wish to do this, please contact
                        the Information Commissioner’s Office at www.ico.org.uk
                        or the following address: Information Commissioner’s
                        Office, Wycliffe House, Water Lane, Wilmslow, Cheshire,
                        SK9 5AF.
                    </li>
                    <li>
                        Take us or the Information commissioner’s office to
                        court. If you wish to do this then please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        or write to us at Roundups Fundraising Services Ltd, 97
                        Charlotte Street, London, W1T 4QA.
                    </li>
                    <li>
                        Withdraw consent to process your data at any time. If
                        you wish to do this, please email{' '}
                        <a href="mailto:hello@joinripples.org">
                            hello@joinripples.org
                        </a>{' '}
                        with your request or write to us at Roundups Fundraising
                        Services Ltd, 97 Charlotte Street, London, W1T 4QA.
                    </li>
                </ul>
                <p>Additionally, we must inform you of the following;</p>
                <ul>
                    <li>
                        If we suffer a data breach and your data is effected in
                        way that it poses a risk to your rights and freedoms.
                    </li>
                    <li>
                        If we carry out your request to correct/update, erase or
                        not use your data
                    </li>
                </ul>
                <h3>Contact information</h3>
                <p>Contact Details for the controller of your data:</p>
                <p>
                    Roundups Fundraising Services Ltd, 97 Charlotte Street,
                    London, W1T 4QA
                    <br />
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                    <br />
                    0203 730 7647
                </p>
                <p>Contact Details for the data protection champion:</p>
                <p>
                    Sean Donnelly
                    <br />
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                    <br />
                    0203 730 7647
                </p>
                <p>
                    However if you are still looking for more information then
                    you can email{' '}
                    <a href="mailto:hello@joinripples.org">
                        hello@joinripples.org
                    </a>
                    .
                </p>
                <h4>Roundups’ ICO Registration Number is: ZA768618</h4>
            </section>
        </Layout>
    </>
);

export default PrivacyPolicy;
